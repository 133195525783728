.tree {
  list-style: none;
  margin: 0;
  padding: 0;
}
.tree-node,
.tree-node-group {
  list-style: none;
  margin: 0;
  padding: 0;
}

.tree-branch-wrapper,
.tree-node__leaf {
  outline: none;
  margin-top: 3px;
  margin-bottom: 3px;
}

.tree-node__branch {
  display: flex;
  align-items: center;
}

.tree-node {
  cursor: pointer;
}

.tree-node .MuiFormControlLabel-root {
  margin: 0;
}

.tree-node .MuiCheckbox-root {
  padding: 0;
}

.tree-node .MuiTypography-root {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 300px;
  white-space: nowrap;
}

.tree-node-arrow {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tree-node-arrow svg {
  height: 12px;
}

.tree-node-offset {
  display: inline-flex;
  width: 20px;  
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-icon {
  animation: spinner 1.5s linear infinite;
  margin-right: 2px;
}

.visually-hidden {
  position: absolute;
  clip-path: circle(0);
  border: 0;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  width: 1px;
  white-space: nowrap;
}